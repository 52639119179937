@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
